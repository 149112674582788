@import "./../../styles/variables.scss";

.listEtapes{
    list-style: square outside;
    margin-left: 20px;
    padding-left: 0;

}

p{
    font-size: 20px !important;
    @include breakpoint(dn) {
        font-size:16px !important;
    }
}
.link{
    color: $orange !important;
}
.form-group{
    display: flex;
}
.listEtapes {
    list-style: none;
    @include breakpoint(dn) {
        font-size:14px !important;
        text-align: left;
    }
  }
  
  .listEtapes li::before {
    content: "✅";
    line-height: 2;
    display: inline-block;
    margin-right: 0.2rem;

  }
  