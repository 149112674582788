@import url('https://rsms.me/inter/inter.css');

$Poppins: "Inter", sans-serif;

//colors
$blue : #003B6F;
$dark: #023047;
$light: #F5F7F9;
$primary: #8ECAE6;
$grey: #B4BEC8;
$yellow : #EEC643;
$red : #E27D60;
$light_blue:#BBDFF0;
$orange: #FB8500;
$bg: #E8A87C;

$light_grey: #E5E5E5;

//spacing
$spacing-md: 16px;
$spacing-lg: 32px;

//border radius
$borderRadius: 12px;

//header height
$headerHeight: 70px;

@mixin breakpoint($point){
    @if $point == md{
        // 768px
        @media (min-width: 768px){
            @content;
        }
    }
    @if $point == mn{
        @media (max-width: 1399px){
            @content;
        }
    }
    @if $point == dr{
        @media (max-width: 993px){
            @content;
        }
    }
    @if $point == dn{
        @media (max-width: 820px){
            @content;
        }
    }
    @if $point == ch{
        @media (max-width: 820px){
            @content;
        }
    }
}