@import "./../../styles/variables.scss";

.orange{
    color: $orange;
    text-align: left;
}
.article{
    display: flex;
    @include breakpoint(dn) {
        display:contents;
    }
    .source{
        text-align: right;
    }
}