@import "./../../../styles/variables.scss";

.pageNotFound{
    text-align: center;
      background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  .btnHome{
      color: $dark;
      border: 1px solid $dark;
      padding: 10px;
      border-radius: 10px;
      &:hover{
          color: white;
        background-color: $dark;
      }
  }
  img{
      margin-top: 10px;
      @include breakpoint(dn) {
        width: 400px;
        height: 400px;
    }
  }
  }