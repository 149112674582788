.crudContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dn{
        display: none;
    }
    .item{
        margin-top:5px;
        border-bottom: 10px;
       }
    .combo{
        margin: 0;
        margin-top:5px;
    }
}
