
.navbar{
    height: 80px;
    border-bottom: 0.5px solid lightgray;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
    .wrapper{
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .holder{
            display: flex;
            align-items: center;
            border: 0.5px solid transparent;
            padding: 3px;

        }

        .items{
            display: table;
            align-items: center;
            .dn{
                display: none;
            }
            .logOut{
                margin: 0;
                padding: 0;
                
            }
            .transparent{
                background: transparent;
            }
            
            .item{
                display: table-cell;
                align-items: center;
                margin-right: 20px;
                position: relative;
                .icon{
                    font-size: 20px;
                }
                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: 5px;
                    right: 15px;

                }
            }
        }
    }
}