@import "./../../styles/variables.scss";
a:link, a:visited,  a:hover, a:active
{
    color: white;
    text-decoration: none;
}
.RGPD{
    position: fixed;
    right: 0;
    width: 40px;
    margin-top: 80px ;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 10px;
    border: none;
    color: white;
    background-color: $dark;
    font-size: 25px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    .hideText{
        display: none;
    }
    .hideIcon{
        font-size: 35px;
        width: 30px;
        font-weight: bold;
    }
    &:hover{
        width: 100px;
        transition: width 0.1s ease-in 0.1s;
        .hideIcon{
            display: none;
        }
        .hideText{
            text-align: center;
            font-size: 25px;
            font-weight: bold;
            display:initial;
        }
    }
}
.FAQ{
    position: fixed;
    right: 0;
    width: 40px;
    margin-top: 20px ;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 10px;
    border: none;
    color: white;
    background-color: $orange;
    font-size: 25px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    .hideText{
        display: none;
    }
    .hideIcon{
        font-size: 35px;
        width: 30px;
        font-weight: bold;
    }
    &:hover{
        width: 100px;
        transition: width 0.1s ease-in 0.1s;
        .hideIcon{
            display: none;
        }
        .hideText{
            text-align: center;
            font-size: 25px;
            font-weight: bold;
            display:initial;
        }
    }
}
.container_hero{
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include breakpoint(dn) {
        display: contents;
    }
    @include breakpoint(dr) {
        display: block;
        text-align: center;
        
    } 
    .definition{
        img{
            width: 50%;
        }
        flex-basis:60%;
        overflow: hidden;
        @include breakpoint(dr) {
            flex-basis: 100%;
            
        } 
        @include breakpoint(dn) {
            text-align: center;
            
            img{
                width: 50%;
            }
        }
    }
    .disnone{
        @include breakpoint(dr) {
            display: none;
        }
    }
    h1{
        color: $dark;
    }
    p{
       font-size: 22px;
       padding: 10px;
       @include breakpoint(dn) {
        font-size:18px;
    }
    }
    .grey{
        color: rgba($dark, $alpha: 0.6);
        width: 520px;
        text-align:justify;
        line-height: 1.8;
        @include breakpoint(dn) {
            width: auto;
            margin-right: 20px;
            margin-left: 20px;
            text-align: center;
        }
        @include breakpoint(dr) {
            width: 100%;
        }
        @include breakpoint(mn) {
            width: 100%;
        }
    }
    .logo{
        height: auto;
        max-height: 320px;
        width: 900px;
     
        @include breakpoint(dn) {
            width: auto;
            height: 300px;
        }
        @include breakpoint(dr) {
            width: auto;
            text-align: center;
            img{
                width: 400px;
            }
        } 
    }
    .illus{
        flex-basis: 70%;
    @include breakpoint(dn) {
        text-align: center;
    }
}
    .btn_simulateur{
        background-color: #8ECAE6;
        width: 250px;
        padding: 10px;
        border: none;
        border-radius: 10px;
        margin-top: 10px;
        color: white;
    }
    .gear_icon img{
        width: 30px;
        margin-right: 10px;
    }
}

.plusMinus{
    font-size: 30px;
    color: $orange;
    text-align: center;
    line-height : 20px;
   
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}
.row{
    justify-content: center;

}
.fw-bold{
    padding: 10px;
    font-size: 16px;
}
.col{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    max-width: 45%;
    text-align: justify;
    padding: 0;
    p{
        padding: 10px;
        font-size: 16px;
    }
    @include breakpoint(dn) {
        text-align: center;
    }
}
.text_orange{
    color: $orange !important;
}