@import "../../styles/variables.scss";
body {
  background-color: $light;
  color: $dark;
  font-family: "Raleway", sans-serif;
}
form{
  margin-top: 98px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 10px ;
  margin-bottom: 98px;
}
.illustration {
  margin-top: 98px;
  @media (max-width: 991px){
    display: none;
  }
}
.container{
  margin-bottom: 50px;
}
.contact__form  {
  .form-control{
    
    padding: 1.375rem 0.75rem;
    line-height: 1.5;
    color: $dark;
    background-color: $light;
    border-radius: 5px !important;
    border: 1px solid $grey;
  }
  input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + 0.75rem + 2px);
  }
}

.btn {
  color: $dark;
  border: 1px solid $grey;;
  width: 150px;
  height: 50px;
  &:hover {
    background: $dark;;
    color: $light;
  }
  
}

.submit_btn{
  text-align: right;
  @include breakpoint(dn) {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
 }
  & > *{
    width: 50%;
  }
}

.contactus{
    @media (max-width: 991px){
    text-align: center;
  }
}