@import "./../../styles/variables.scss";

.wrapper{
    justify-content: center;
    align-items: center;
}
.accordion{
    width: 600px;
    
    @include breakpoint(dn) {
        align-items: center;
        justify-content: center;
        display: initial;
    
       width: 500px;
    }
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-shadow: -1px 5px 4px -3px #000000;
    border-radius: 10px;
    padding: 10px;

}
.question{
    font-size: 20px;
}

.item{
    background: $light;
    margin-bottom: 5px;
    padding: 10px 20px;
}

.categ{
    padding-left: 20px;
}
.content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}
.contentShow{
    height: auto;
    max-height: 9999;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    padding: 10px;
}
h2{
    margin-top: 80px;
    text-align: center;
}