
.dataTables{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    .crud{
        align-items: right;
        justify-content: right;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        align-items: right;
        margin-bottom: 5px;
     }
}
