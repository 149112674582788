.list{
    display: flex;
    width: 100%;
    .listContainer{
        flex: 6;
        display: flex;
        .crudUsers{
            width: 30%;
        }
        .dataTables{
            width: 70%; 
        }
    }
    .hide{
        display: none;
    }
}
