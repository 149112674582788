@import "../../../styles/variables.scss";

#formContainer{
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: none;
    margin-top: 20px;

    #btnSubmit{
        background-color: transparent;
        color: $dark;
        border: 1px solid $dark;
       &:hover{
           color: $light;
           background-color: $dark;
       }
    }
}
.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
}
.offscreen{
    display: none;
}
.loginIcon{
    text-align: center;
    font-size: 30px;
}