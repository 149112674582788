@import "../../../styles/variables.scss";
.home{
    display: flex;
    
    .homeContainer{
        background-color: $light;
        flex:6;
    }
    .listContainer{
        -webkit-box-shadow: 2px 4px 10px 1px $dark;
        box-shadow: 2px 4px 10px 1px lightgray;
        padding: 20px;
        margin: 20px;
        .items{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .listTitle{
            font-weight: 500;
            color: gray;
            margin-bottom: 15px;
        }
        .TextareaAutosize{
            background-color: transparent;
            border-radius: 5px;
            border: 1px solid lightgray;
            padding: 5px;
            &:hover{
                border: 1px solid black;  
            }
            &:focus{
                outline: none;
                border: 2px solid #0040ff	;
            }
        }
    }
}
.text-justify{
    text-align: center;
}