@import "./../../styles/variables.scss";

.Tabs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabsList{
  display: flex;
  align-items: center;
  justify-content: center;
  .tabHead{
    padding: 1.5rem;
    border: 1px solid black;
    height: 80px;
    @include breakpoint(dn) {
      font-size: 12px;
      padding: 0;
      padding-top: 1.5rem;
      justify-content: center;
      justify-items: center;
      align-items: center;
      width:100px;
      height: 100px;
  }
  }
}
.active{
  background: $dark;
  color: white;
}
table{
  text-align: left;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 180px;
  
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba($color: $light_blue, $alpha: 0.5);
  @include breakpoint(dn) {
    width: 180px;
    font-size: 14px;
}
  th{
    border-bottom: 1px solid $dark;
    width: 230px;
  }
  td, th{
    padding: 12px 15px;
  }
  td,tr{
    background-color: $light;

  }
}