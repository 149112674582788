@import "./../../styles/variables.scss";

p{
    font-size: 18px !important;
}
.center{
    text-align: center;
    color: grey;
}
.gray{
    color: grey;
}
.question{
    color: $blue;
}
.a{
    color: $orange !important;
}