
.datatables{
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
}
.cellWithStatus {
    padding: 5px;
    border-radius: 5px;
    &.Admin{
        color: blue;
        background-color: rgba($color: blue, $alpha: 0.151)
    }
    &.Utilisateur{
        color: goldenrod;
        background-color: rgba(189,189,3,0.151);
    }
    &.Terminer{
        color: green;
        background-color: rgba(0,128,0,0.151);
    }
    &.En{
        color: goldenrod;
        background-color: rgba(189,189,3,0.151);
    }
    &.Pas{
        color: red;
        background-color: rgba($color: red, $alpha: 0.151)
    }
    
    
}
.cellAction{
    display: flex;
    align-items: center;
    gap: 15px;
    .modifyButton{
        padding: 2px 5px;
        border-radius: 5px;
        color: darkorange;
        border: 1px solid rgba($color: darkorange, $alpha: 0.6);
        cursor: pointer;
    }
    .deleteButton{
        padding: 2px 5px;
        border-radius: 5px;
        color: darkred;
        border: 1px solid rgba($color: darkred, $alpha: 0.6);
        cursor: pointer;
        button{
            color: darkred;
        }
    }
    button{
        border: none;
    }

}
.titre{
    text-align: center;
}
