@import "./../../styles/variables.scss";


.header {
    background: $light;
    width: 100%;
    height: $headerHeight;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
    position: fixed;
    top: 0;
    transition-timing-function: ease-in;
    transition: 0.5s;
    overflow: hidden;
    z-index: 999;
    @include breakpoint(md) {
        padding: 0 3rem;
    }

    &__content {
        overflow: hidden;
        color: $dark;
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 100;
        
        &__logo {
            font-size: 2rem;
            font-weight: bold;
            text-decoration: none;
            color: $dark;
        }

        &__nav {
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: rgba($primary, 0.9);
            backdrop-filter: blur(2px);
            transform: translate(0);
            transition: 0.3s ease transform;

            @include breakpoint(md) {
                transform: none;
                flex-direction: row;
                background: transparent;
                width: auto;
                height: 100%;
                position: static;
            }
            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: $spacing-lg;
                font-size: 1rem;
                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: calc(0.5rem + #{$spacing-md});
                }
                li {
                    
                    &:not(:last-child) {
                        margin-bottom: $spacing-lg;
                        @include breakpoint(md) {
                            margin-bottom: 0;
                            margin-right: $spacing-md;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: inherit;
                        padding: 0.75rem 1.25rem;
                        transition: 0.3s ease all;
                        
                        &:hover {
                            border-bottom: 1px solid $dark;
                        }
                    }

                }
            }
            button {
                cursor: pointer;
                outline: none;
                padding: 0.75rem 1.25rem;
                border-radius: $borderRadius;
                font-size: 1rem;
                font-family: inherit;
                background: $dark;
                color: white;
                border: 1px solid transparent;
                transition: 0.3s ease all;

                &:hover {
                    border-color: $dark;
                    background: rgba($primary, 0.1);
                    color: $dark;
                }
                &:active {
                    border-color: $primary;
                    background: linear-gradient(rgba($primary, 0.2), rgba($primary, 0.3));
                    color: $primary;
                }
            }
            &.isMenu {
                transform: translate(100%);
            }
           
        }

        &__toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 2rem;
            transition: 0.3s ease all;
            position: relative;

            &:hover {
                color: $primary;
            }

            @include breakpoint(md) {
                display: none;
            }
        }
    }
}

.header__content__logo {
    width:17%;
    img{
        width: 100%;
        height:100%;
        -o-object-fit:cover;
        object-fit:cover;
        background: transparent;
        margin: 0;
    }
    @include breakpoint(ch) {
        width:30%;
    }

}