@import "./variables.scss";

* {
    box-sizing: border-box;
    margin: 0;
}

body{
    width: 100%;
    font-family: $Poppins;
    color: $dark;
    padding-top: 65px; 
}

#root{
    height: 100%;
}

:root {
    --primary-color: #5bb462;
    --primary-dark: #4f9c55;
    --secondary-color: #ffea00;
    --secondary-dark:#e3d002;
    --overlay: rgba(0,0,0,.7);
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    line-height: 1.2;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  ul {
    list-style-type: none;
  }
  
  a {
    text-decoration: none;
  }
  .button {
    padding: 17px 28px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }
  
  .line {
    display: block;
    width: 40px;
    height: 3px;
    margin: 5px auto;
    background-color: var(--primary-color);
  }