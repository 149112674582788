@import "./../../styles/variables.scss";


.definition p{
    font-size:20px !important;
    text-align: justify;
    line-height: 2;
    @include breakpoint(dn) {
        font-size:16px !important;
    }
    .listSimulateur {
        list-style-type: circle;
    }
}
.InputSimulateur{
    width: 100%;
    background-color:$light_grey;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    margin-top: 98px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    @include breakpoint(dr) {
        display: block;
    }

    .Donnees{
        text-align: center;
    }
    .radio {
        .radioG{
            margin: 10;
        }
    }
    .chart{
        width: 75%;
        @include breakpoint(dr) {
            width: 100%;
        }
    }
    
    .chart1{
        text-align: center;
        max-height: 600px;
        height: 500px;
        margin-bottom: 20px;
    }
    
    .calculate{
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        border: 1px solid $dark;
    }
    input{
        border-radius: 5px;
        border: 1px solid $dark;
    }
    
}
