@import "../../../styles/variables.scss";
.dn{
    display: none;
}
.sidebar{
    background-color: $light;
    border-right: 0.5px solid lightgray;
    min-height: 100vh;
    margin: 0;
    flex: 1;
    .active-link{
        background-color: red;
    }
    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo{
            font-size: 20px;
            font-weight: bold;
            color:$blue;
        }
    }
    hr{
        height: 0;
        border: 0.5px solid lightgray;
    }

    .center{

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            li{
                display: flex;
                align-items: center;
                padding: 4px;
                cursor: pointer;

                &:hover{
                    background-color: #ece8ff;
                }

                .icon{
                    font-size: 18px;
                    color: $blue;
                }

                span{
                    font-size: 16px !important;
                    font-weight: 600;
                    color: $dark;
                    margin: 10px;
                }
            }
        }
    }
}